* {
    box-sizing: border-box;
}

html, body {
    font-size: 16px;
}

html, body, #root {
    background-color: #F0F0F0;
    margin: 0;
    height: 100%;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

input {
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-size: 1em;
}

#root {
    display: flex;
    padding: 15px;
}

img {
    width: 100%;
    height: auto;
}

.main-container {
    margin: auto;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: stretch;
    max-width: 1000px;
    max-height: 600px;
    width: 100%;
    height: 100%;
    box-shadow: 0 5px 20px 0 rgba(0,0,0,0.1);
}

.main-container .left {
    position: relative;

    .inner {
        position: absolute;
        top: 0;
        left: 0;
        padding: 1rem;

        .logo {
            max-width: 120px;
        }
    }

    .bg {
        max-height: 100%;
        height: 100%;
    }

    @media only screen and (max-width: 576px) {
        display: none;
    }
}

.main-container .right {
    flex: auto;
    background-color: #ffffff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    padding: 0 45px;
}

.right-wrapper {
    display: flex;
    flex-direction: column;
    flex: 1;

    > .bottom {
        margin: 0 auto;
        max-width: 350px;
        width: 100%;
        margin-bottom: 1rem;
    }
}

.inner-wrapper {
    margin: auto;
    width: 100%;
    max-width: 350px;

    > .top {
        color: #1F4C6D;
        font-family: 'Poppins';
        font-size: 1.875em;
        font-weight: 600;
    }

    > .second {
        margin-top: 5px;
        font-size: 0.9em;
    }
}

.form {
    margin-top: 1.5rem;

    .button {
        margin-top: 2rem;
    }
}

.form-input {
    margin-top: 5px;
    margin-bottom: 1.2rem;
    display: flex;
    flex-direction: column;
    position: relative;

    input {
        height: 50px;
        border-radius: 10px;
        border: 1px solid #CDCDCD;
        padding: 1rem;

        &.error {
            border-color: #FF0E0E;
        }

        &:focus {
            outline: none;
            border-color: #F58A1F;
        }
    }

    > .input-suffix {
        right: 0;
        position: absolute;
        display: flex;
        height: 100%;
        align-items: center;
        padding-right: 1rem;
        color: #555555;
    }
}

.button {
    height: 50px;
    border: none;
    padding: 1rem 2rem;
    border-radius: 5px;
    cursor: pointer;
    min-width: 150px;

    &:hover {
        box-shadow: 0 0 10px 0 rgba(0,0,0,0.2);
    }
}

.button-primary {
    @extend .button;
    background-color: #F58A1F;
    color: #ffffff;
}

.text-muted {
    color: #A0A0A0;
}

a {
    color: #1F4C6D;
}

.error-msg {
    color: #FF0E0E;
    margin-top: 1rem;
}

.msg {
    margin-top: 1rem;
    color: #28a745;
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    align-self: center;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #F58A1F;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #F58A1F transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}
  